var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('h1',[_vm._v("Submitted Ideas")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('el-tooltip',{attrs:{"content":"refresh","placement":"top"}},[_c('span',{staticClass:"clickable"},[_c('i',{staticClass:"fas fa-redo",class:_vm.$apollo.loading ? 'fa-spin' : '',on:{"click":function($event){_vm.$apollo.queries.get_hackathon_approval_ideas.skip = false;
                                    _vm.$apollo.queries.get_hackathon_approval_ideas.refetch();}}})])])],1)],1),(_vm.$apollo.loading && _vm.table_data.length == 0)?_c('div',[_c('b-row',{staticClass:"mt-2 d-flex align-items-center justify-content-center"},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center"},[_c('Transition',[_c('h1',[_c('i',{staticClass:"fas fa-spinner fa-spin ml-1"})])])],1)],1)],1):_c('b-row',[_c('b-col',[_c('el-table',{staticClass:"table-responsive align-items-center table-flush no-lines",attrs:{"header-row-class-name":"thead-light","row-class-name":"table-rows","data":_vm.table_data,"empty-text":'No ideas have been submitted.'}},[_c('el-table-column',{attrs:{"label":"Name","min-width":"150px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return [_c('p',{staticClass:"mb-0"},[_c('router-link',{attrs:{"to":{
                                            name: 'HackathonApproveIdea',
                                            params: {
                                                hackathon_id: _vm.hackathon_id,
                                                idea_id: row.id,
                                            },
                                        }}},[_vm._v(" "+_vm._s(row.name)+" ")])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Submitter","min-width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
return [_c('a',{attrs:{"href":'mailto:' + row.creator.email}},[_vm._v(_vm._s(row.creator.first_name)+" "+_vm._s(row.creator.last_name))])]}}])}),_c('el-table-column',{attrs:{"min-width":"90px","label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('b-badge',{attrs:{"pill":"","variant":row.approval.state.name ===
                                        'APPROVED'
                                            ? 'info'
                                            : 'primary'}},[_vm._v(_vm._s(row.approval.state.display_name))])],1)}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }